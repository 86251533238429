// .drag_column_header,
// .react-kanban-column-header {
//   margin: 40px 10px 15px;
// }

.drag_column_header > div,
.react-kanban-column-header,
.react-kanban-column-adder-button {
  width: 100%;
  padding: 20px;
  height: 60px;
  background: var(--color__primary);
  border-radius: 5px;
  border: none;
  border-top: 1px solid rgba(72, 118, 128, 1) !important;
}

.react-kanban-column form button {
  background-color: #fff !important;
}

.drag_column_header h3,
.react-kanban-column-header span {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  margin: 0;
  color: var(--color__white) !important;
}

.drag_column_header h3 {
  display: flex;
  justify-content: space-between;
}

.drag_column_header h3 div {
  display: flex;
  gap: 5px;
}

.drag_column_header {
  margin-bottom: 12px;
}

.drag_column_header > div {
  padding: 10px;
}

.drag_column_header p {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  margin: 0;
  color: var(--color__white) !important;
}

.drag_column_header input,
.react-kanban-column-header input {
  background-color: transparent !important;
  outline: none;
  // border: none;
  border: 1px solid #ffffff61;
  color: var(--color__white);
  width: 100%;
  padding: 4px;
  margin-bottom: 8px;
}

.react-kanban-column-adder-button {
  width: 230px;
}

.react-kanban-column-adder-button::after {
  content: "Add pipeline";
  color: var(--color__white);
  margin-left: 8px;
}

.react-kanban-column form {
  gap: 8px;
}

.react-kanban-column form button {
  background: var(--color__secondary) !important;
  padding: 8px;
  border: none;
  color: #fff;
  border-radius: 4px;
  line-height: 16px;
}

.react-kanban-column form input {
  padding-left: 8px;
}

.react-kanban-column form button:nth-child(3) {
  background: #424848 !important;
}

.d-none {
  display: none !important;
}

.drag_column_header *,
.react-kanban-card {
  color: var(--color__white) !important;
}

.react-kanban-card {
  background-color: var(--color__primary);
}

[data-rbd-droppable-id="1"] .react-kanban-card {
  background-color: #62a2b0;
}

[data-rbd-droppable-id="2"] .react-kanban-card {
  background-color: #7e92c6;
}

[data-rbd-droppable-id="3"] .react-kanban-card {
  background-color: #dfa0a0;
}

[data-rbd-droppable-id="4"] .react-kanban-card {
  background-color: #6aa181;
}

.react-kanban-card__title {
  border: none;
}

.react-kanban-card__description {
  padding-top: 0px;
  padding-bottom: 15px;
}

// [data-rbd-droppable-context-id] {
//   min-width: 100% !important;
// }

.react-kanban-board,
.react-kanban-column {
  background-color: transparent;
}

.react-kanban-column-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
}

.drag_column_header button {
  display: flex;
  align-items: center;
}

.react-kanban-card-adder-button {
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px;
  padding-top: 3px;
}

// add item button

// .react-kanban-card-adder-button {
//   display: none;
// }


.drag_column_header input + button {
  display: inline-flex;
}

.drag_column_header input {
  width: 80%;
  margin-right: 10px;
}