.main-container {
  width: calc(100% - var(--width__sidebar));
  background-color: var(--color__black);
  transition: background-color 0.3s;
  overflow: auto;
  height: 100vh;
  padding-bottom: 50px;



  &__header {
    padding-block: 73px 30px;
    padding-inline: 40px 56px;

    &-nav {
      width: fit-content;
      margin-left: auto;
      gap: 30px;

      input[type="search"] {
        background-image: url("../../public/assets/icons/icon-search.svg");
        background-position: right 20px center;
        background-color: var(--color__primary);
        background-repeat: no-repeat;
        color: var(--color__white);
        border: none;
        padding: 10px 25px;
        width: 282px;
        border-radius: 57px;
        font-size: 14px;
        line-height: 20px;
        padding-right: 50px;

        &:focus-visible {
          outline-color: var(--color__secondary);
          border: none;
        }
      }
    }
  }
}
