.sidebar {
  background-color: var(--color__sidebar-bg);
  max-width: var(--width__sidebar);
  padding: 60px 45px 108px 45px;
  width: 100%;
  min-height: 100vh;
  max-height: 100%;
  transition: background-color 0.3s;
  overflow: auto;

  @media (max-width: 1600px) {
    padding-inline: 20px;
  }

  @media (max-height: 880px) {
    padding-block: 60px;
  }

  .brand {
    path {
      color: var(--color__white);
    }
  }
}

.sidebar-navigation,
.sidebar-secondary-navigation {
  align-self: flex-start;
  list-style-type: none;
  color: var(--color__white);
  margin-top: 125px;
  gap: 30px;

  @media (max-height: 920px) {
   gap: 10px;
   margin-top: 20px;
  }


  li {
    a {
      text-decoration: none;
      color: var(--color__white);
      display: flex;
      align-items: center;
      gap: 30px;
      font-size: 18px;
      font-weight: 500;
      line-height: 20px;

      path[stroke="white"] {
        stroke: currentColor;
      }

      &:hover,
      &.active {
        color: var(--color__secondary);
      }
    }
  }
}


@media (max-height: 920px) {
  .sidebar-navigation {
    margin-top: 70px;
  }
}

.sidebar-secondary-navigation {
  margin-top: auto;
}

.theme-switch {
  gap: 5px;
  margin-bottom: 18px;

  button {
    height: 33px;
    width: 50px;
    background-color: var(--color__mode-inactive);

    &:nth-child(1) {
      box-shadow: 0px 4px 4px 0px #00000040 inset;
      background-color: var(--color__mode);
      // border: 1px solid red;

      [stroke="white"] {
        stroke: #0BDEDE ;
      }
    }

     &:nth-child(2) {
      background-color: var(--color__secondary);
     }
  }

  button:nth-child(1) {
    border-radius: 20px 0px 0px 20px;
  }

  button:nth-child(2) {
    border-radius: 0 20px 20px 0;
  }
}

.social_links a {
  margin: 10px 20px;
}

[data-theme="light"] {
  .theme-switch {
    button:nth-child(1) {
      background-color: #0BDEDE;
      path {
        stroke: white;
      }
    }
  
    button:nth-child(2) { 
      background-color: #131313;

      path {
        stroke: white;
      }
    }
  }
}