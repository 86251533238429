.todo-list {
  margin-top: 30px;
  max-height: 350px;
  overflow-y: auto;
}

.todo-list .dashboard__label {
  font-size: 16px;
  margin-bottom: 12px;
  display: flex;
  align-content: center;
  gap: 10px;
}
.todo-list .dashboard__label:before {
  content: "";
  display: inline-block;
  width: 24px;
  height: 24px;
  background-image: url("data:image/svg+xml,%3csvg%20width='24'%20height='24'%20viewBox='0%200%2024%2024'%20fill='none'%20xmlns='http://www.w3.org/2000/svg'%3e%3cpath%20d='M8%205H4C3.44772%205%203%205.44772%203%206V10C3%2010.5523%203.44772%2011%204%2011H8C8.55228%2011%209%2010.5523%209%2010V6C9%205.44772%208.55228%205%208%205Z'%20stroke='white'%20strokeWidth='2'%20strokeLinecap='round'%20strokeLinejoin='round'/%3e%3cpath%20d='M3%2017L5%2019L9%2015'%20stroke='white'%20strokeWidth='2'%20strokeLinecap='round'%20strokeLinejoin='round'/%3e%3cpath%20d='M13%206H21'%20stroke='white'%20strokeWidth='2'%20strokeLinecap='round'%20strokeLinejoin='round'/%3e%3cpath%20d='M13%2012H21'%20stroke='white'%20strokeWidth='2'%20strokeLinecap='round'%20strokeLinejoin='round'/%3e%3cpath%20d='M13%2018H21'%20stroke='white'%20strokeWidth='2'%20strokeLinecap='round'%20strokeLinejoin='round'/%3e%3c/svg%3e");
}
.todo-list__todo {
  background-color: var(--color__dashboard-card);
  font-size: 11px;
  font-weight: 500;
  padding: 10px 15px;
  color: var(--color__white);
  margin-bottom: 8px;
  border-radius: 4px;
}
.todo-list__todo h3 {
  font-size: 12px;
  font-weight: 500;
  line-height: 1.2;
}
.todo-list__todo .flex {
  gap: 11px;
}
.todo-list__todo p {
  color: inherit;
}