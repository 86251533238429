// Header.scss

.main-container__header {
    &-nav {
        display: flex;
        align-items: center;

        .notification-dropdown {
            position: absolute;
            top: 140%; // Position below the notification icon
            right: 0;
            min-width: 300px;
            background-color: var(--color__dashboard-card);
            box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
            border-radius: 4px;
            border: 1px solid var(--color__broad-btn);
            padding: 10px;
            z-index: 100;
            display: none; // Initially hidden

            &::before {
                content: "";
                position: absolute;
                top: -16px;
                right: 12px;
                border-width: 8px;
                border-style: solid;
                border-color: transparent transparent var(--color__broad-start-color) transparent;
                display: none;
            }

            p {
                padding: 8px 12px;
                cursor: pointer;

                // &:hover {
                //     background-color: #f0f0f0;
                // }
            }
        }
    }
}

.notification-dropdown h3{
    font-size: 16px;
    color: var(--color__white);
}

.notification-dropdown p{
    color: var(--color__white);
    margin-top: 6px;
    margin-bottom: 4px;
    font-size: 14px;
    padding-bottom: 4px;
    border-bottom: 1px solid rgba(214, 214, 214, 0.2);
}

// Animation for dropdown
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

// Apply fadeIn animation to the dropdown
.main-container__header-nav .notification-dropdown {
    animation: fadeIn 0.2s ease-in-out;
}

// Show the dropdown when isDropdownOpen is true
.main-container__header-nav .notification-dropdown {
    display: block;
}