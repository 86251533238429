.tabs_content > div {
  display: none;
}

.tabs_content > div.active {
  display: block;
}

.tabs {
  margin-top: 100px;
}

.tab_link {
  color: var(--color__white);
  display: block;
  padding-bottom: 12px;
  margin-bottom: 12px;
  font-size: 14px;
  font-weight: 500;
  border-bottom: 1px solid rgba(219, 219, 219, 1);
  text-decoration: none;
}

.tab_links {
  padding-right: 110px;

  @media (max-width: 1440px) {
    padding-right: 20px;
  }
}

.tab_link.active {
  color: var(--color__secondary);
}

.user_img {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  overflow: hidden;
}

.user_img img {
  width: 100%;
  object-fit: cover;
}

.setting_form {
  margin-top: 30px;
}

.setting_form .input-group {
  display: flex;
  align-items: center;
  gap: 13px;
  margin-bottom: 30px;
}

.setting_form .input-group input,
.setting_form .input-group select {
  width: 650px;
  border: 0.25px solid var(--color__white);
  height: 40px;
  background-color: transparent;
  color: var(--color__white);
  padding-left: 12px;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  border-radius: 5px;
}

.setting_form .input-group label {
  color: var(--color__white);
  margin: 0;
  margin-right: 20px;
  width: 190px;
  text-align: right;
}

.setting_form textarea {
  width: 750px;
  height: 250px;
  border: 0.25px solid var(--color__white);
  background-color: transparent;
  color: var(--color__white);
  padding-left: 12px;
  border-radius: 5px;
  resize: none;
}

@media (max-width: 1300px) {
  .setting_form textarea {
    width: 500px;
  }
}

.user_list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 30px;
}

.user_list > div {
  border: 1px solid rgba(19, 19, 19, 0.01);
  background: rgba(19, 19, 19, 0.82);
  border-radius: 10px;
  padding: 12px;
  text-align: center;
}

.user_card h2 {
  font-size: 18px;
  font-weight: bold;
}

.user_card p {
  font-size: 16px;
  color: var(--color__white);
  margin: 0;
  line-height: normal;
}

.user_card .btns button {
  margin: 0 5px;
  color: var(--color__white);
  font-size: 14px;
}

.add_item_card {
  min-height: 260px;
  justify-content: center;
}

.add_item_card a {
  text-align: center;
  color: rgba(26, 175, 175, 1);
  text-decoration: none;
}


.setting-page {
  .dashboard__btn {
    background-color: var(--color__secondary);
    color: var(--color__black);
    font-size: 14px;
    font-weight: 600;
  }

  .dashboard__btn_white {
    font-size: 14px;
  }
}

.setting-checkboxes {
  gap: 70px;

  label {
    font-size: 14px;
    margin-left: 10px;
  }
}