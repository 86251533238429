.usr_ticket {
  background: rgba(87, 146, 189, 1);
  padding: 14px;
  padding-bottom: 35px;
  border-radius: 8px;
}

.usr_ticket p,
.usr_ticket h2 {
  color: var(--color__white) !important;
}

[data-rbd-draggable-id] {
  padding: 0 !important;
}

.rbc-calendar {
  background: var(--color__calendar);
  padding: 20px;
  border-radius: 12px;
}

.rbc-off-range {
  opacity: 20%;
}

.rbc-off-range-bg,
.rbc-today {
  background-color: transparent !important;
}

.rbc-button-link,
.rbc-header {
  color: var(--color__calendar-text);
}

// .rbc-date-cell {
//   text-align: center;
//   padding-top: 30px;
// }
.rbc-date-cell {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  height: 40px;
}

.rbc-toolbar {
  margin-bottom: 20px;
}

.rbc-toolbar * {
  color: var(--color__calendar-text) !important;
}

.rbc-label, .rbc-agenda-content, .rbc-agenda-empty {
  color: var(--color__calendar-text);
}

.rbc-addons-dnd-resizable {
  display: flex;
  justify-content: center;
  align-items: center;
}

.rbc-now > button {
  height: 40px;
  width: 40px;
  background-color: var(--color__secondary);
  border-radius: 10px;
}

.rbc-active {
  color: black !important;
}

// .rbc-btn-group:last-child {
//   display: none !important;
// }

.rbc-day-bg + .rbc-day-bg,
.rbc-month-row + .rbc-month-row,
.rbc-month-view,
.rbc-header {
  border: none !important;
}

.rbc-toolbar button:focus,
.rbc-toolbar button:hover {
  color: #000 !important;
}

.event_catagory {
  margin-bottom: 20px;
  justify-content: space-between;
  width: 100%;
}

.event_catagory p {
  font-family: Satoshi, sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: var(--color__white);
  margin: 0;
  margin-right: 40px;

  @media (max-width: 1240px) {
    margin-right: 10px;
  }
}

.event_catagory span {
  background: rgba(254, 230, 201, 1);
  width: 15px;
  height: 15px;
  transform: translateY(2px);
  border-radius: 50%;
  margin-right: 6px;
  display: inline-block;
}

.evnt_viewing span {
  background: rgba(255, 217, 217, 1);
}

.evnt_calls span {
  background: rgba(210, 240, 255, 1);
}

.event_catagory {
  a {
    display: flex;
    color: var(--color__white);
    align-items: center;
    text-decoration: none;
    margin-left: 30px;
  }

  svg path {
    stroke: var(--color__white);
  }
}

.rbc-event,
.rbc-day-slot .rbc-background-event,
.evnt_calls span {
  background-color: #d2f0ff;
  color: #000;
}

.rdp-caption_label {
  font-size: 12px;
}

.rdp-dropdown:focus {
  background: transparent;
}

.rdp-dropdown:focus-visible:not([disabled]) + .rdp-caption_label {
  background-color: transparent;
  border: none;
}
