.chat__selected {
    background-color: var(--color__secondary);
    color: black;
}

.chat__body {
    height: 80%;
    overflow-y: scroll;
    /* border: 1px solid #e5e5ea; */
    border-radius: 0.25rem;
    display: flex;
    flex-direction: column;
    /* font-size: 1.25rem; */
    margin: 0 auto 1rem;
    padding: 0.5rem 1.5rem;
  }
  
  .chat__body::-webkit-scrollbar {display: none;}

  .chat__body p, .chat__body div {
    border-radius: 1.15rem;
    line-height: 1.25;
    max-width: 75%;
    padding: 0.5rem .875rem;
    position: relative;
    word-wrap: break-word;
  }
  
  .chat__body p::before,
  .chat__body p::after,
  .chat__body div::before,
  .chat__body div::after  {
    bottom: -0.1rem;
    content: "";
    height: 1rem;
    position: absolute;
  }
  
  p.from-me, div.from-me {
    align-self: flex-end;
    background-color: var(--color__secondary);
    color: #000; 
  }
  
  p.from-me::before, div.from-me::before {
    border-bottom-left-radius: 0.8rem 0.7rem;
    border-right: 1rem solid var(--color__secondary);
    right: -0.35rem;
    transform: translate(0, -0.1rem);
  }
  
  p.from-me::after, div.from-me::after {
    background-color: var(--color__dashboard-card);
    border-bottom-left-radius: 0.5rem;
    right: -40px;
    transform:translate(-30px, -2px);
    width: 10px;
  }
  
  p[class^="from-"], div[class^="from-"] {
    margin: 0.5rem 0;
    width: fit-content;
  }
  
  p.from-me ~ p.from-me, div.from-me ~ div.from-me {
    margin: 0.25rem 0 0;
  }
  
  p.from-me ~ p.from-me:not(:last-child), div.from-me ~ div.from-me:not(:last-child) {
    margin: 0.25rem 0 0;
  }
  
  p.from-me ~ p.from-me:last-child, div.from-me ~ div.from-me:last-child {
    margin-bottom: 0.5rem;
  }

  div.from-me img {
    width: 50%;
  }
  
  p.from-them {
    align-items: flex-start;
    background-color: #e5e5ea;
    color: #000;
  }
  
  p.from-them:before {
    border-bottom-right-radius: 0.8rem 0.7rem;
    border-left: 1rem solid #e5e5ea;
    left: -0.35rem;
    transform: translate(0, -0.1rem);
  }
  
  p.from-them::after {
    background-color: var(--color__dashboard-card);
    border-bottom-right-radius: 0.5rem;
    left: 20px;
    transform: translate(-30px, -2px);
    width: 10px;
  }
  
  p[class^="from-"].emoji {
    background: none;
    font-size: 2.5rem;
  }
  
  p[class^="from-"].emoji::before {
    content: none;
  }
  
  .no-tail::before {
    display: none;
  }
  
  .margin-b_none {
    margin-bottom: 0 !important;
  }
  
  .margin-b_one {
    margin-bottom: 1rem !important;
  }
  
  .margin-t_one {
    margin-top: 1rem !important;
  }

  /*Broadcast Page*/
  .contact-upload-container {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    justify-content: center;
    align-items: center;
    align-self: flex-end;
    margin-left: 19rem;
  }

  .contact-upload-container * {
    color: var(--color__white) !important;
  }

  .contact-upload-container a {
    text-decoration: none;
  }

  .contact-upload-container > button, .contacts-upload-btn {
    height: 32px;
    font-size: 12px;
    padding: 12px 12px 12px 2px;
  }

  .contacts-upload-btn {
    padding: 10px;
    width: 96px;
    height: 28px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 110%;
  }

  .contact-upload-btns-cont {
    justify-content: flex-end;
    width: 100%;
  }