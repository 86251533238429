.payment-container {
  width: 100%;
  max-width: 772px;
  margin-top: 308px;
  margin-inline: auto;
  text-align: center;

  input[type=file] {
    width: 100%;
    display: none;
  }

  h2 {
    margin-bottom: 46px;
  }

  .file-label {
    border: 1px solid #BBB7B7;
    padding: 10px 27px;
    display: block;
    text-align: left;
    border-radius: 7px;
    margin-bottom: 31px;
    background-repeat: no-repeat;
    background-position: right 10px center;
    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5 3H19' stroke='white' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'/%3E%3Cpath d='M18 13L12 7L6 13' stroke='white' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'/%3E%3Cpath d='M12 7V21' stroke='white' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'/%3E%3C/svg%3E%0A");
  }

  .terms {
    text-align: left;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    color: #BBB7B7BB;
    display: flex;
    align-items: center;

    a {
      font-weight: 700;
      color: inherit;
    }

    input {
      width: 22px;
      height: 22px;
      margin-right: 10px;
      accent-color: #A1A1A1;
    }
  }

  .btn--submit {
    margin-left: auto;
    margin-top: 40px;
  }
}

.payment-container h2 {
  font-size: 32px;
  font-weight: 700;
}