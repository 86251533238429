.rdp {
  --rdp-cell-size: 40px;
  /* Size of the day cells. */
  --rdp-caption-font-size: 16px !important;
  /* Font size for the caption labels. */
  --rdp-accent-color: #0000ff;
  /* Accent color for the background of selected days. */
  --rdp-background-color: #e7edff;
  /* Background color for the hovered/focused elements. */
  --rdp-accent-color-dark: #3003e1;
  /* Accent color for the background of selected days (to use in dark-mode). */
  --rdp-background-color-dark: #180270;
  /* Background color for the hovered/focused elements (to use in dark-mode). */
  --rdp-outline: 2px solid var(--rdp-accent-color);
  /* Outline border for focused elements */
  --rdp-outline-selected: 3px solid var(--rdp-accent-color);
  /* Outline border for focused _and_ selected elements */
  --rdp-selected-color: #fff;
  /* Color of selected day text */
}

.rdp-weeknumber,
.rdp-day,
.rdp-head_cell {
  font-size: 13px !important;
}

// .rdp-head_cell{
//     color: #0DC4C4;
// }

// .rdp-cell{
//     color: #fff;
// }

// .rdp-caption_dropdowns{
//     color: #fff;
// }

// .rdp-nav{
//     color: #fff;
// }

.rdp-button:hover:not([disabled]):not(.rdp-day_selected) {
  color: var(--color__schedule-date);
}

.rdp-day_selected,
.rdp-day_selected:focus-visible,
.rdp-day_selected:hover {
  background-color: var(--color__schedule-date);
}
