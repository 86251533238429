.welcome {
  background-image: url("../../public/assets/images/welcome-detail.png");
  background-size: cover;
  height: 100vh;
  background-size: 100%;
  background-repeat: no-repeat;
  background-color: black;


  .page__logo {
    max-width: 440px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 100px;
  }

  .btn-continue {
    position: absolute;
    bottom: 210px;
    right: 0;

    @media (max-width: 1400px) {
      right: 200px
    }
  }

  &__container {
    width: 100%;
    margin-inline: auto;
    max-width: 1320px;
    position: relative;
  }
}
