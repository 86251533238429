.rbc-time-content > * + * > * {
    border-left: 1px solid #dddddd6b;
}

.rbc-timeslot-group {
    border-bottom: 1px solid #dddddd6b;
}

.rbc-day-slot .rbc-time-slot {
    border-top: 1px solid #f7f7f76b;
}

.add-event-container {
    z-index: 4;
}

.leads-popup > .add-event-popup > form > input[readonly] {
    background-color: var(--color__input);
    color: var(--color__white);
    padding: 7px;
    border: none;
    font-size: 14px;
    border-radius: 6px;
}

.add-event-popup {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.add-event-popup > input, .add-event-popup > select {
    width: 100%;
    height: 2.5rem;
    outline: none;
}

.add-event-popup select {
    background-color: var(--color__input);
    color: var(--color__white);
    padding: 7px;
    border: none;
    font-size: 14px;
    border-radius: 6px;
}

.add-event-popup > textarea {
    appearance: none;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.2px;
    letter-spacing: -0.01em;
    text-align: left;
    background-color: var(--color__input);
    outline: none;
    width: 100%;
    padding: 7px;
    border: none;
    border-radius: 9px;
    color: var(--color__table);
    resize: none;
    height: 150px;
    margin-bottom: 20px;
}