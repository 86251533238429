.dashboard {
  padding-inline: 40px;
  color: var(--color__white);
  font-size: 24px;
  line-height: 32.4px;
  overflow: auto;
  padding-bottom: 60px;
  height: 80vh;

  &::-webkit-scrollbar {
    width: 7px;
  }

  &::-webkit-scrollbar-track {
    background-color: var(--color__primary);
    border-radius: 6px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 6px;
    background: var(--color__secondary);
  }

  &__container {
    display: grid;
    grid-template-columns: 1014px 520px;
    margin-top: 18px;
    gap: 28px;

    @media (max-width: 1900px) {
      grid-template-columns: 75% 300px;
    }

    @media (max-width: 1500px) {
      grid-template-columns: 1fr;

      .dashboard__card {
        height: auto;
      }
    }
  }

  &__top-row {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 15px;

    &-card {
      background-color: var(--color__dashboard-card);
      border-radius: 10px;
      padding: 25px;
      color: var(--color__white);

      h4 {
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
      }

      h2 {
        font-size: 64px;
        font-weight: 500;
        line-height: 1.2;
      }

      p {
        margin-top: 20px;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
      }
    }
  }

  &__second-row {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 12px;
    margin-top: 21px;

    &-summary-wrapper {
      gap: 17px;
    }

    &-summary {
      font-size: 10px;
      font-weight: 500;
      line-height: 20px;
      display: flex;
      align-items: center;
      gap: 7px;
    }

    .status {
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: var(--color__status);
    }

    .dashboard__card {
      max-height: 200px;
      color: var(--color__white);
      margin-top: 14px;

      p {
        color: inherit;
        font-size: 14px;
        line-height: 20px;
      }
    }

    &-inner {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 12px;
    }
  }

  &__card {
    background-color: var(--color__dashboard-card);
    border-radius: 10px;
    padding: 25px;
    color: var(--color__white);
    height: 100%;

    h4 {
      font-size: 14px;
      font-weight: 700;
      line-height: 20px;
    }

    h2 {
      font-size: 64px;
      font-weight: 500;
      line-height: 1.2;
    }

    svg {
      margin-bottom: 20px;
    }

    p {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
    }
  }

  &__label {
    color: var(--color__white);
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
  }

  &__btn {
    background: var(--color__progress-bar);
    font-size: 10px;
    font-weight: 700;
    line-height: 20px;
    text-align: center;
    padding-block: 10px;
    width: 100%;
    margin-top: 24px;
    border-radius: 28px;
    color: var(--color__black);
  }

  &__third-row {
    margin-top: 30px;

    &-inner {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      gap: 15px;
      margin-top: 14px;
    }

    .dashboard__card {
      display: flex;
      gap: 12px;
      align-items: flex-start;

      h2 {
        font-size: 51px;
        font-weight: 500;
        line-height: 1;

        span {
          font-size: 14px;
          font-weight: 500;
          display: inline-block;
          white-space: nowrap;
        }
      }

      p {
        color: var(--color__white);
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
      }
    }
  }

  &__fourth-row {
    margin-top: 26px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;

    .dashboard__card {
      margin-top: 14px;
      display: flex;
      gap: 12px;
      align-items: flex-start;
    }

    table {
      width: 100%;
    }

    tr {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      text-align: left;

      td {
        text-align: center;

        &:first-child {
          text-align: left;
        }
      }
    }

    tr:not(:first-child) {
      td {
        padding-top: 17px;
      }
    }

    th {
      font-size: 14px;
      font-weight: 700;
      line-height: 20px;
      text-align: center;

      &:first-child {
        text-align: left;
      }
    }
  }

  &__calender {
    width: 100%;
    // height: 300px;
    background-color: var(--color__dashboard-card);
    padding: 10px;

    .rdp {
      margin: 0;
    }
  }

  .rdp-day_selected,
  .rdp-day_selected:focus-visible,
  .rdp-day_selected:hover,
  .rdp-button:hover:not([disabled]):not(.rdp-day_selected) {
    background-color: var(--color__secondary);
    border-radius: 10px;
    box-shadow: 0px 4px 4px 0px #00000040 inset;
    border: none;
  }

  &__upcomming-events {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-top: 22px;
  }

  &__upcomming-event {
    height: 100px;
    width: 100%;
    background-color: var(--color__dashboard-card);
    border-left: 9px solid var(--color__dark-secondary);
    padding: 12px 17px 9px 30px;

    &:first-child {
      border-color: var(--color__secondary);
    }

    h3 {
      font-size: 12px;
      font-weight: 700;
      line-height: 16.2px;
      text-align: left;
      margin-bottom: 12px;
    }

    p {
      color: inherit;
      font-size: 12px;
      font-weight: 300;
      line-height: 16.2px;
    }
  }

  .todo-list {
    margin-top: 30px;

    .dashboard__label {
      font-size: 16px;
      margin-bottom: 12px;
      display: flex;
      align-content: center;
      gap: 10px;

      &::before {
        content: "";
        display: inline-block;
        width: 24px;
        height: 24px;
        background-image: url(../../public/assets/icons/icon-todo.svg);
      }
    }

    &__todo {
      background-color: var(--color__dashboard-card);
      font-size: 11px;
      font-weight: 500;
      padding: 10px 15px;
      color: var(--color__white);
      margin-bottom: 8px;
      border-radius: 4px;

      h3 {
        font-size: 12px;
        font-weight: 500;
        line-height: 1.2;
      }

      .flex {
        gap: 11px;
      }

      p {
        color: inherit;
      }
    }
  }
}

.cal_title {
  font-family: "League Spartan";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #acadad;
}

.rdp-caption_dropdowns {
  border: 1px solid #4c4a4a;
  border-radius: 8px;
}

.rdp-day_outside {
  color: #645ac3 !important;
  opacity: 1 !important;
}

.rdp-caption {
  padding: 12px 8px;
}
