@import '../components/forms';
@import '../components/buttons';

.login-page {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
  max-width: 1370px;
  margin-inline: auto;
  color: var(--color__white);

  form {
    width: 390px;
  }

  @media (max-width: 1470px) {
    justify-content: center;
    gap: 100px;

    img[alt="logo large"] {
      max-width: 50%;
    }
  }
}