.search-result {
  width: 100%;
  padding-inline: 40px 105px;

  h3 {
    font-size: 16px;
    font-weight: 700;
    line-height: 21.6px;
    color: var(--color__table);

    span {
      font-size: 14px;
      font-weight: 500;
      line-height: 18.9px;
    }
  }

  &__wrapper {
    width: 100%;
    background-color: var(--color__dashboard-card);
    margin-top: 26px;
    border-radius: 3px;
    padding: 25px;
    color: var(--color__white);

    &-col {
      width: 300px;
    }

    button {
      background-color: var(--color__chat-submit);
      padding: 10px 17px;
      border-radius: 38px;
    }
  }

  &__property {
    font-size: 14px;
    font-weight: 700;
    line-height: 18.9px;
    text-align: right;
    width: 106px;
  }

  @media (max-width: 1370px) {
    .gap-200 {
      gap: 50px;
    }
  }
}