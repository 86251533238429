.help_modal {
  position: fixed;
  width: 80%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--color__broad-start-color);
  padding: 80px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  z-index: 99999;
  border: 4px solid var(--color__broad-btn);
  color: var(--color__white);
}

.close-button {
  position: absolute;
  top: -20px;
  right: -30px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #a8a4a4;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.2s;
}

.close-button:hover {
  transform: rotate(180deg);
  background-color: var(--color__broad-btn);
}

.tab-button {
  color: #fff;
  margin: 0 20px;
}

.mySwiper {
  padding: 40px 0;
}

.tab-button.active {
  color: aqua;
}

.model_tabs_content {
  color: #fff;
}

.help_text {
  font-size: 40px;
  color: #fff;
  padding-right: 20px;
  font-family: "League Spartan", sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 52px;
  letter-spacing: 0.12em;
}

.swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff !important;
}

.swiper-slide span {
  display: block;
  color: #fff !important;
  text-align: center;
  margin-top: 20px;
}

.swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet,
.swiper-pagination-horizontal.swiper-pagination-bullets
  .swiper-pagination-bullet {
  background-color: #fff;
  opacity: 1;
}

.swiper-pagination-bullet-active {
  background-color: var(--color__broad-btn) !important;
}

.swiper-button-prev {
  display: none;
}

.swiper-button-prev,
.swiper-button-next {
  top: auto;
  bottom: 54px;
  right: 50px;
  color: #fff !important;
  width: 80px;
}

.swiper-button-next::after {
  display: none;
}
