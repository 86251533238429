/* Knowledge Add Section */
.knowledgeadd {
  width: 100%;
  padding-inline: 50px;
  height: 100%;
}
@media (max-width: 1850px) {
  .knowledgebase {
    height: 80vh;
  }
}

/* Knowledge Add Header Section */
.knowledgeadd__header {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.knowledgeadd__header-button {
  padding: 10px 20px;
  background-color: var(--color__mode-inactive);
  color: #a4a4a4;
  border: none;
  border-radius: 20px;
  cursor: pointer;
}

.knowledgeadd__header-button a {
  color: #a4a4a4;
  text-decoration: none; /* Remove underline */
}

.knowledgeadd__header-button.active {
  background-color: var(--color__secondary);
  color: var(--color__black);
}

.knowledgeadd__headerbar {
  background: var(--color__secondary);
  color: var(--color__black);
  height: 40px;
  padding: 12px;
  border-radius: 10px;
  text-align: left;
  margin-bottom: 20px;
}

.knowledgeadd__image-gallery {
  display: flex;
  gap: 10px;
}

.knowledgeadd__image-box {
  width: 150px;
  height: 150px;
  /* background: #333; */
  background: var(--color__dashboard-card);
  border: 1px solid #444;
}

/* Select Menu */
.knowledgeadd__select-container {
  display: flex;
  gap: 10px;
  margin-top: 20px;
}

.knowledgeadd__custom-select {
  /* background-color: #1f2430; */
  background-color: var(--color__dashboard-card);
  color: var(--color__white);
  border: none;
  padding: 10px;
  border-radius: 5px;
  width: 100%;
  font-size: 16px;
}

.knowledgeadd__form-fields {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 10px;
  align-items: center;
  margin-bottom: 20px;
  border: none;
  /* border: 1px solid #444; */
  /* padding: 5px; */
}

.knowledgeadd__form-fields label {
  flex: 1;
  text-align: center;
  padding: 5px;
  border: 1px solid #444;
  color: var(--color__white);
}

.knowledgeadd__textarea-label {
  min-height: 200px;
  max-height: 500px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.knowledgeadd__textarea-input {
  min-height: 200px;
  max-height: 500px;
}

.knowledgeadd__textarea-input-img {
  min-height: 200px;
  max-height: 500px;
  text-wrap-mode: nowrap;
}

.knowledgeadd__form-fields input,
.knowledgeadd__form-fields textarea {
  flex: 2;
  background: #222;
  color: #fff;
  border: none;
  padding: 5px;
  border: 1px solid #444;
}

/* section group Section */

.knowledgeadd__section-group {
  margin-bottom: 20px;
}

.knowledgeadd__section {
  margin-bottom: 25px;
}

.knowledgeadd__section-header {
  background-color: var(--color__secondary);
  padding: 10px 15px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.knowledgeadd__section-header span {
  font-size: 16px;
}

.knowledgeadd__section-content {
  background-color: #444;
  padding: 10px 15px;
  margin-top: 5px;
  border-radius: 5px;
}

/* Footer Section */
.knowledgeadd__footer {
  margin-top: 20px;
  display: grid;
  grid-template-columns: 0fr 1fr;
  gap: 10px;
  color: var(--color__white);
}

.knowledgeadd__buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  gap: 10px;
  height: 50px;
}

.knowledgeadd__cancel,
.knowledgeadd__draft {
  background: var(--color__mode-inactive);
  color: #a4a4a4;
  padding: 20px;
  border: none;
  border-radius: 20px;
}

.knowledgeadd__submit {
  background: var(--color__secondary);
  color: var(--color__black);
  padding: 20px;
  border: none;
  border-radius: 20px;
}

.knowledgeadd__scraped-img {
  display: flex;
  flex-wrap: nowrap; /* Prevent images from wrapping to the next line */
  overflow-x: auto; /* Enable horizontal scrollbar if content overflows */
  margin: 0;
  max-width: 100%; /* Set the maximum width */
}
.knowledgeadd__scraped-img img {
  width: 200px;
  padding: 10px;
}

.knowledgeadd__success-dialog,
.knowledgeadd__error-dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgb(0, 0, 0); /* Black background */
  padding: 20px;
  border: 1px solid #00e8d9; /* Thin blue border */
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.5); /* White shadow */
  z-index: 1000;
  border-radius: 5px; /* Rounded corners */
  text-align: center;
}

.knowledgeadd__success-dialog p {
  color: white;
  font-size: 1.2rem;
  margin-bottom: 20px;
}

.knowledgeadd__error-dialog p {
  color: red;
  font-size: 1.2rem;
  margin-bottom: 20px;
}

.knowledgeadd__success-dialog button,
.knowledgeadd__error-dialog button {
  background-color: var(--color__secondary);
  border: none;
  color: #121212;
  padding: 10px 20px;
  margin: 0 10px;
  cursor: pointer;
  border-radius: 50px;
  transition: background-color 0.3s ease;
  height: 40px;
  width: 80px;
}

.knowledgeadd__success-dialog button:hover,
.knowledgeadd__error-dialog button:hover {
  background-color: #00bfa5; /* Darker blue on hover */
}
