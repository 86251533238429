.leads {
  padding-inline: 40px;

  &__header {
    padding-right: 30px;
  }

  &__preview {
    gap: 18px;

    button {
      border: none;
      outline: none;
      background-color: transparent;
      color: var(--color__white);
    }

    button.active {
      path {
        color: var(--color__secondary);
      }
    }
  }

  &__container {
    gap: 12px;
    margin-top: 25px;
    overflow-y: scroll;
    height: 100%;
    max-height: 65vh;
    padding-right: 20px;

    &::-webkit-scrollbar {
      width: 7px;
    }

    &::-webkit-scrollbar-track {
      background-color: var(--color__primary);
      border-radius: 6px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 6px;
      background: var(--color__secondary);
    }

    @media (min-height: 1100px) {
      max-height: 860px;
    }

    &:not(.leads__container--table) {
      display: grid;
      grid-template-columns: repeat(5, 1fr);

      @media (max-width: 1536px) {
        grid-template-columns: repeat(3, 1fr);
      }
    }
  }

  &__card {
    background-color: var(--color__primary);
    color: var(--color__white);
    border-radius: 3px;
    height: 96px;
    padding: 13px 5px 4px 10px;
    transition: background-color 0.3s;

    &-info {
      display: flex;
      justify-content: space-between;
    }

    &-status {
      margin-top: 20px;
      display: flex;
      justify-content: space-between;

      p {
        text-wrap: nowrap;
      }
    }

    &-level {
      display: flex;
      align-items: center;
      gap: 5px;
    }

    h4 {
      font-size: 14px;
      font-weight: 500;
      line-height: 18.9px;
    }

    p {
      color: inherit;
      font-size: 12px;
      font-weight: 400;
      line-height: 16.2px;

      b {
        text-transform: capitalize;
      }
    }
  }

  table {
    min-width: 1210px;
    width: 100%;
    color: var(--color__white);
    text-align: center;
    border-collapse: collapse;
    border: none;
  }

  table tr:nth-child(even),
  table .thead {
    background-color: var(--color__primary);
  }

  table td {
    padding: 15px;
  }

  table .thead td {
    font-family: "League Spartan", sans-serif;;
  }

  table tr:not(.thead) td {
    color: var(--color__table);
  }
}

.leads-overlay {
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
}

.leads-popup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 730px;

  &__container {
    border: 0.5px solid #0BDEDE;
    border-radius: 13px;
    background-color: var(--color__dashboard-card);
    padding: 26px 42px 26px 26px;
    color: var(--color__white);
    position: relative;
  }
  
  &__inner {
    display: flex;
    justify-content: space-between;
  }

  .input-group {
    display: flex;
    justify-content: flex-end;
    gap: 14px;
    margin-top: 40px;

    .btn {
      margin: 0;
      font-size: 14px;
      font-weight: 500;
    }
  }

  span:not(.span) {
    font-size: 14px;
    font-weight: 500;
    line-height: 12.88px;
    min-width: 60px;
    display: inline-block;
    text-align: right;
    margin-right: 27px;
  }

  input {
    background-color: var(--color__input);
    color: var(--color__white);
    padding: 7px;
    border: none;
    font-size: 14px;
    border-radius: 6px;
  }

  input[readonly] {
    background-color: transparent;
    padding: 0;
    border-radius: 0;
  }

  &__row {
    margin-bottom: 17px;;
  }

  &__col--large {
    span:not(.span) {
      width: 150px;
    }
  }

  .span {
    display: inline-flex;
    gap: 3px;
    font-size: 14px;
  }

  &__edit {
    position: absolute;
    top: 23px;
    right: 19px;
    
  }
}

.leads-popup--delete {
  max-width: 566px;

  .leads-popup__container {
    text-align: center;
    color: var(--color__white);
  }

  .input-group {
    justify-content: center;
  }

  h3 {
    font-size: 22px;
    font-weight: 500;
    line-height: 33px;
  }

  p {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: inherit;
  }
}