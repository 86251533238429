@import 'fonts';
@import 'headings';

body {
  font-family: var(--font__body);
}

p, a { color: var(--color__primary)}

.capitalize { text-transform: capitalize; }
.uppercase { text-transform: uppercase; }

.small-text { font-size: 14px; }
.base-text { font-size: 16px; }
.medium-text { font-size: 18px; font-weight: 400; font-family: 'visby-medium'; }

b, strong, .bold { font-family: var(--font__headings); }

blockquote cite { font-family: var(--font__headings); font-style: normal; }