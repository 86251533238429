.packages-switch {
  display: flex;
  justify-content: center;
  gap: 3px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);

  button {
    width: 180px;
    padding-block: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: 500;
    line-height: 16.8px;
    background: #373636;
  }

  button.active {
    background: var(--color__secondary);
    color: var(--color__black);
  }

  button:first-child {
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
  }

  button:last-child {
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
  }
}

.packages {
  grid-template-columns: repeat(3, 1fr);
  gap: 40px;
  
  &:not(.individual-package) {
    display: none;
  }

  &__card {
    border: 1px solid var(--color__secondary);
    color: var(--color__white);
    padding: 70px 34px 40px;
    text-align: left;
    border-radius: 16px;

    h2 {
      color: var(--color__secondary);
      font-size: 40px;
      font-weight: 400;
      line-height: 40px;
      margin-bottom: 22px;
    }

    p {
      font-size: 20px;
      font-weight: 500;
      line-height: 22px;
       color: var(--color__white);
    }

    .price {
      margin-top: 80px;
      /* font-family: Roboto; */
      font-size: 48px;
      font-weight: 400;

      sub {
        font-size: 24px;
      }
    }

    ul {
      margin-top: 80px;
      list-style-position: inside;

      li {
        list-style-image: url("data:image/svg+xml,%3Csvg width='26' height='20' viewBox='0 0 26 26' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clipPath='url(%23clip0_60_26779)'%3E%3Cpath d='M17.4688 10.5625L11.507 16.25L8.53125 13.4062' stroke='%230BDEDE' strokeWidth='1.625' strokeLinecap='round' strokeLinejoin='round'/%3E%3Cpath d='M13 22.75C18.3848 22.75 22.75 18.3848 22.75 13C22.75 7.61522 18.3848 3.25 13 3.25C7.61522 3.25 3.25 7.61522 3.25 13C3.25 18.3848 7.61522 22.75 13 22.75Z' stroke='%230BDEDE' strokeWidth='1.625' strokeLinecap='round' strokeLinejoin='round'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_60_26779'%3E%3Crect width='26' height='26' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
      }

      li:marker {
        border: 1px solid red;
      }
    }

    .btn--started {
      margin-top: 90px;
      margin-inline: auto;
    }
  }

  &.active {
    display: grid;
  }
}

.individual-package__container {
  width: 100%;
  max-width: 1520px;
  margin-inline: auto;
  display: flex;
  justify-content: space-between;

  .options__container {
    max-width: 950px;
    margin-left: 0;
  }

  .options__form {
    background-color: var(--color__white);
    height: 660px;
    width: 415px;
    margin-top: 270px;
    border-radius: 10px;
  }

  .packages__card {
    position: relative;
    padding: 98px 74px 70px 73px;

    p {
      font-size: 16px;
    }
  }

  .individual-package__price {
    position: absolute;
    right: 74px;
    bottom: 70px;
    text-align: right;

    .switch-container {
      margin-top: 50px;
      gap: 6px;
      align-items: center;
    }
  }

  .switch {
    &:is(label) {
      display: block;
      font-size: 32px;
      background: #474747;
      width: max-content;
      height: 1em;
      aspect-ratio: 1.625;
      border-radius: 1em;
      position: relative;
      cursor: pointer;
      transition: background 200ms var(--nq-ease, ease);

      &::after {
        content: "";
        position: absolute;
        height: 0.8em;
        top: 0.1em;
        left: 0.1em;
        aspect-ratio: 1;
        border-radius: 1em;
        background-color: var(--color__secondary);
        transition: left 200ms var(--nq-ease, ease);
      }

      &:has(input[type="checkbox"][disabled]) {
        cursor: not-allowed;

        &:has(input[type="checkbox"]:checked) {
          opacity: 0.4;
        }
      }

      &:has(input[type="checkbox"]:checked) {
        background-image: var(--nq-blue-gradient);

        &:has(input[type="checkbox"]:not([disabled])):hover {
          background-image: var(--nq-blue-gradient-darkened);
        }

        &::after {
          left: calc(100% - 0.9em);
        }
      }

      &:has(input[type="checkbox"]:focus-visible) {
        outline-offset: 3px;
      }

      input[type="checkbox"] {
        position: absolute;
        width: 1px;
        height: 1px;
        padding: 0;
        margin: -1px;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        white-space: nowrap;
        border-width: 0;
      }
    }
  }
}