body.bg {
  background-color: var(--color__black);
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.brand {
  font-size: 28px;
  font-weight: 700;
  text-decoration: none;
}

.translate-x-center {
  left: 50%;
  transform: translateX(-50%);
}

.flex-vertically-center {
  display: flex;
  align-items: center;
}

.background-element {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.seaction-title {
  margin-bottom: 8px;
}

.text-decoration-none {
  text-decoration: none;
}

.d-none {
  display: none !important;
}

.inline-block {
  display: inline-block !important;
  width: fit-content !important;
}

.profile-pic {
  width: 51px;
  height: 51px;
  border-radius: 50%;
  background-color: var(--color__primary);
  overflow: hidden;

  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
  }
}

.mt-10 {
  margin-top: 10px;
}

.mt-14 {
  margin-top: 14px;
}

.mt-25 {
  margin-top: 25px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-18 {
  margin-top: 18px;
}

.mt-45 {
  margin-top: 45px;
}

.mt-12 {
  margin-top: 12px;
}

.mt-130 {
  margin-top: 130px;
}

.mb-130 {
  margin-bottom: 130px;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mt-40 {
  margin-top: 40px !important;
}

.ml-20 {
  margin-left: 20px;
}

.color-white {
  color: var(--color__white);
}

.color-secondary {
  color: var(--color__secondary);
}

.color-dark-secondary {
  color: var(--color__dark-secondary);
}

.bg-secondary {
  background-color: var(--color__secondary) !important;
}
.bg-dark-secondary {
  background-color: var(--color__dark-secondary) !important;
}

.font-14 {
  font-size: 14px;
}

.no-grid {
  display: block !important;
}

[fill=svgNumber] {
  fill: var(--color__white);
}

.weight-medium {
  font-weight: 500 !important;
}

[data-theme="light"] [fill="#292929"] {
  fill: var(--color__primary);
}
