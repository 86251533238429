.options {
  .page__logo {
    position: absolute;
    width: 206px;
    height: 105px;
    top: 90px;
    left: 100px;
  }

 &__container {
  position: relative;
    width: 100%;
    max-width: 1320px;
    margin-inline: auto;
    margin-top: 145px;
    padding-top: 130px;
    text-align: center;

    .btn-back {
      position: absolute;
      right: 0;
      top: 0;
    }

    .h1 {
      font-size: 42px;
    }
  }
}