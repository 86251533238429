.analytics {
  width: 100%;
  max-width: 1700px;
  padding-inline: 50px;
  height: 100%;
  overflow: auto;

  @media (max-width: 1850px ) {
    height: 80vh;
  }

  &__first-row {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 11px;

    @media (max-width: 1350px) {
      grid-template-columns: repeat(2, 1fr);
    }

    .progress label {
      font-size: 22px;
      font-weight: 500;
      line-height: 20px;
      margin-bottom: 14px;
      display: block;
    }

    .dashboard__second-row-summary-wrapper {
      margin-top: 10px;
    }
  }

  .dashboard__card {
    height: auto;
    padding-bottom: 0;
  }

  th {
   font-weight: 500;
  }

  &__card {
    background-color: var(--color__dashboard-card);
    color: var(--color__table);
    padding: 20px 20px 10px 20px;
    border-radius: 10px;
    height: 233px;

    .status {
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: #F1EBEB;;
    }

    h3 {
      font-size: 15px;
      font-weight: 500;
      line-height: 20px;
    }

    &-inner {
      gap: 20px;
      margin-top: 27px;

      h2 {
        font-size: 24px;
        line-height: 20px;
      }

      p {
        color: var(--color__table);
        font-size: 14px;
        line-height: 20px;
        margin-top: 5px;
      }
    }

    a {
      color: var(--color__secondary);
      font-size: 12px;
      line-height: 20px;
      text-decoration: none ;
    }

    table {
      width: 100%;

      td, th {
        padding-bottom: 10px;
      }

      th {
        padding-bottom: 20px;
      }

      td {
        font-size: 14px;
      }

      td:nth-child(3) {
        width: 200px;
      }

      td:nth-child(1) {
        width: 165px;
      }

      td:nth-child(2) {
       text-align: center;
      }
    }
    
    table.two-columns {
      td:nth-child(2) {
        width: 200px;
      }
    }

    &--with-chart {
      display: flex;
      align-items: start;
      align-items: center;
      margin-top: 20px;
      color: var(--color__table);
      padding-top: 10px;
      gap: 30px;

      .flex {
        gap: 30px;
      }

      .flex > div {
        width: 105px;
      }

      .cart-item {
        width: 135px;
        height: 135px;
      }

      h3 {
        font-size: 45px;
        line-height: 20px;
        font-weight: 500;
      }

      p {
        margin-top: 23px;
        color: inherit;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
      }
    }

    .text-white {
      color: var(--color__table);
    }
  }

  .analytics__card.label-white p {
    color: pink;
  }

  .analytics__card-v2 {
    padding-block: 14px 18px;

    @media (max-width: 1900px) {
      height: auto !important;
    }
  }

  .analytics__card-v3 {
    padding-block: 11px 15px;
  }

  &__second-row {
    margin-top: 18px;
    display: grid;
    grid-template-columns: 1fr 1fr 360px;
    gap: 18px;

    @media (max-width: 1850px) {
      grid-template-columns: 1fr 1fr;
    }

    @media (max-width: 1440px) {
      grid-template-columns: 1fr;
    }
  }

  table.progress-second {
    td:nth-child(1) {
      width: 150px;
    }

    td:nth-child(2) {
      width: 160px;
      align-items: center;
    }

    td:nth-child(3) {
      width: 100px;
      align-items: center;
      text-align: center;
    }

    .progress-v2 {
      align-items: center;
    }
  }

  .analytics__card-v3 {
    display: grid;
    align-items: center;
    grid-template-columns: 100px 45px 120px;
    gap: 20px;
    // margin-top: 23px;

    p {
      color: var(--color__table);
      font-size: 14px;
    }

    .dashboard__second-row-summary:not(:last-child),
    p:not(:last-child) {
      margin-bottom: 20px;
    }
  }
}

// .analytics-main .main-container {
//   padding-bottom: 0;
//   height: auto;
// }

.analytics-main .analytics {
  height: auto;
  padding-bottom: 0;
}