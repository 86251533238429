/* Knowledge Base Section */
.knowledgebase {
  width: 100%;
  padding-inline: 50px;
  height: 100%;
}

@media (max-width: 1850px) {
  .knowledgebase {
    height: 80vh;
  }
}

/* Knowledge Base Header Section */
.knowledgebase__header {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.knowledgebase__header-button {
  padding: 10px 20px;
  background-color: var(--color__mode-inactive);
  color: #a4a4a4;
  border: none;
  border-radius: 20px;
  cursor: pointer;
}

.knowledgebase__header-button a {
  color: #a4a4a4;
  text-decoration: none; /* Remove underline */
}

.knowledgebase__header-button.active {
  background-color: var(--color__secondary);
  color: var(--color__black);
}

/* Knowledge Base Tabs Section */
.knowledgebase__tabs {
  background: var(--color__dashboard-card);
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 20px;
}

.knowledgebase__tab {
  flex: 1;
  text-align: center;
  padding: 10px;
  border: none;
  background: none;
  color: #bbb;
  font-size: 1rem;
  cursor: pointer;
}

.knowledgebase__tab.active {
  color: white;
  font-weight: bold;
  border-bottom: 2px solid var(--color__secondary);
}

.knowledgebase__cards {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.knowledgebase__card {
  display: flex;
  background-color: var(--color__dashboard-card);
  color: var(--color__white);
  border-radius: 10px;
  padding: 20px;
}

.knowledgebase__card-left {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.knowledgebase__image-container {
  display: flex;
  align-items: flex-start;
  gap: 10px;
}

.knowledgebase__main-image {
  width: 200px;
  height: 200px;
  border-radius: 10px;
}

.knowledgebase__thumbnails {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.knowledgebase__thumbnail {
  width: 60px;
  height: 62px;
  border-radius: 5px;
  object-fit: cover;
  cursor: pointer; /* Change cursor to pointer on hover */
}

.knowledgebase__view-more {
  margin-top: 10px;
  color: #bbb;
  text-decoration: none;
  align-self: center;
}

.knowledgebase__card-right {
  flex: 2;
  padding-left: 20px;
  position: relative;
}

.knowledgebase__title {
  font-size: 22px;
  margin-bottom: 10px;
}

.knowledgebase__subtitle {
  font-size: 18px;
  color: var(--color__white);
  margin-bottom: 10px;
}

.knowledgebase__description,
.knowledgebase__details {
  background-color: var(--color__primary);
  color: var(--color__white);
  margin-bottom: 10px;
  line-height: 1.5;
}

.knowledgebase__info {
  margin-bottom: 10px;
}

.knowledgebase__price-section {
  position: absolute;
  top: 0;
  right: 0;
  text-align: right;
}

.knowledgebase__property-id a {
  font-size: 14px;
  color: var(--color__secondary);
  margin-top: 5px;
  display: block;
}

.knowledgebase__actions {
  display: flex;
  margin-top: 20px;
  justify-content: flex-end;
  gap: 0px;
}

.knowledgebase__icon-button {
  padding: 10px;
  background-color: #121212;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.knowledgebase__marketplace-button {
  padding: 10px 20px;
  background-color: var(--color__secondary);
  color: #121212;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  height: 50px;
}

.knowledgebase__confirmation-dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgb(0, 0, 0); /* Black background */
  padding: 20px;
  border: 1px solid #00e8d9; /* Thin blue border */
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.5); /* White shadow */
  z-index: 1000;
  border-radius: 5px; /* Rounded corners */
  text-align: center;
}

.knowledgebase__confirmation-dialog p {
  color: white;
  font-size: 1.5rem;
  margin-bottom: 20px;
}

.knowledgebase__confirmation-dialog button {
  background-color: var(--color__secondary);
  border: none;
  color: #121212;
  padding: 10px 20px;
  margin: 0 10px;
  cursor: pointer;
  border-radius: 50px;
  transition: background-color 0.3s ease;
  height: 40px;
  width: 80px;
}

.knowledgebase__confirmation-dialog button:hover {
  background-color: #00bfa5; /* Darker blue on hover */
}
