h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5 { font-family: var(--font__headings); }

h1, .h1 {
  font-size: 40px;
  line-height: 1.14;

  @media (--ml) {
    font-size: 56px;
  }
}

h2, .h2 {
  font-size: 28px;

  @media (--ml) {
    font-size: 40px;
  }
}

h3, .h3 { font-size: 36px;}
h4, .h4 { font-size: 24px; }
h5, .h5 { font-size: 20px; line-height: 1.5; }
h6, .h6 { font-size: 16px; line-height: 1.6; font-family: var(--font__body); }