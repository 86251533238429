.broad {
  overflow-x: hidden;
  overflow-y: auto;
  height: 85vh;
  margin-left: 39px;
  max-width: 1490px;

  @media (max-width: 1440px) {
    padding-bottom: 50px;
  }

  &::-webkit-scrollbar {
    width: 7px;
  }

  &::-webkit-scrollbar-track {
    background-color: var(--color__primary);
    border-radius: 6px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 6px;
    background: var(--color__secondary); 
  }

  &__images {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 18px;
    margin-bottom: 30px;

    @media (max-width: 1340px) {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  &__image {
    background-color: var(--color__broad);
    width: 100%;
    height: 159px;
    border-radius: 6px;
    font-size: 12px;
    font-weight: 500;
    line-height: 11.04px;
    letter-spacing: -0.01em;
    text-align: left;
    color: var(--color__table);

    label {
      img {
        margin-bottom: 7px;
      }
    }
  }

  &__broad {
    display: grid;
    grid-template-columns: 864px 392px;
    gap: 76px;

    @media (max-width: 1700px) {
      grid-template-columns: 60% 400px;
      column-gap: 0;
    }

    input[type="text"],
    input[type="number"],
    .input-file,
    textarea,
    select {
      appearance: none;
      font-size: 12px;
      font-weight: 400;
      line-height: 16.2px;
      letter-spacing: -0.01em;
      text-align: left;
      background-color: var(--color__broad);
      outline: none;
      width: 100%;
      padding: 15px 19px;
      border: none;
      border-radius: 9px;
      color: var(--color__table);
      resize: none;

      &::placeholder {
        color: var(--color__table);
      }
    }

    .input-file {
      position: relative;

      &::after {
        width: 20px;
        height: 20px;
        content: "";
        position: absolute;
        right: 14px;
        top: 50%;
        transform: translateY(-50%);
        background-image: url("data:image/svg+xml,%3Csvg width='19' height='19' viewBox='0 0 19 19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.95837 9.5H15.0417' stroke='%237F7B7B' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M9.5 3.95825V15.0416' stroke='%237F7B7B' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
      }
    }

    input[type="file"] {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
    }

    textarea {
      height: 200px;
    }

    .mb-20.flex {
      gap: 16px;
    }
  }

  &__options {
    display: flex;
    justify-content: space-between;

    @media (max-width: 1500px) {
      .flex.align-center {
        flex-direction: column;
        gap: 10px;
        align-items: flex-start;
      }
    }

    label {
      font-size: 12px;
      font-weight: 400;
      line-height: 16.2px;
      letter-spacing: -0.01em;
      text-align: left;
      color: var(--color__table);
      margin-right: 18px;
    }

    select,
    input[id=area], input[id=price], input[id=bathrooms], input[id=bedrooms] {
      width: 107px;
      background-color: var(--color__broad);
      color: var(--color__table);
      border: none;
      outline: none;
      padding: 8px 22px;
      border-radius: 10px;
    }

    button {
      background-color: var(--color__primary);
      color: var(--color__white);
      padding: 10px 14px;
      font-size: 10px;
      font-weight: 500;
      line-height: 13.5px;
      letter-spacing: -0.01em;
      text-align: left;

      &.active {
        background-color: var(--color__broad-btn);
        box-shadow: 0px 1px 1px 0px #00000040 inset;
        color: var(--color__black);
      }

      &:nth-child(1) {
        border-radius: 4px 0 0 4px;
      }

      &:nth-child(2) {
        border-radius: 0px 4px 4px 0px;
      }
    }
  }

  &-btns {
    gap: 11px;
  }

  &__tabs {
    margin-bottom: 15px;
    color: var(--color__white);
    gap: 10px;
  
    button {
      color: inherit;

      &.active {
        color: var(--color__secondary);
      }
    }
  }

  &__history {
    width: 100%;
    padding-right: 20px;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 24px;

    @media (max-width: 1700px) {
      grid-template-columns: repeat(4, 1fr);
    }

    @media (max-width: 1500px) {
      grid-template-columns: repeat(3, 1fr);
    }
  
    &-card {
      padding: 12px;
      border-radius: 5px;
      background-color: var(--color__broad-history-active);
      color: var(--color__table);
      position: relative;

      > img {
        width: 100%;
        height: 207px;
        object-fit: cover;
      }

      h3 {
        font-size: 12px;
        font-weight: 500;
        color: var(--color__table);
        margin-top: 12px;
      }

      p {
        font-size: 10px;
        font-weight: 400;
        color: inherit;
        margin-top: 5px;
      }

      .date {
        margin-top: 20px;
      }
      
      &-actions {
        position: absolute;
        bottom: 20px;
        right: 12px;
        font-size: 10px;
        font-weight: 400;
        display: flex;
        flex-direction: column;
        gap: 5px;

        .flex {
          gap: 2px;
        }

        button {
          width: 16px;
          height: 16px;
          margin-left: auto;
        }
      }

      &-schedule {
        font-size: 9px;
        font-weight: 700;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;

        .flex {
          gap: 3px;
          margin-bottom: 5px;
          font-weight: bold;
        }

      }

      &-schedule-data {
        font-size: 8px;
        font-weight: 500;
        background-color: var(--color__schedule-date);
        padding: 5px 12px;
        border-radius: 4px 4px 0px 0px;
      }
    }
  }

  &__calender {
    border-radius: 8px;
    height: 400px;
    background-color: var(--color__broad);
  }
}

  .broad-container {
    padding-bottom: 0;
  }

[data-theme="light"] .broad__history-card-schedule {
  background-color: var(--color__schedule-date);
  color: var(--color__black);
  border-radius: 4px 4px 0px 0px;
  padding-top: 5px;
}

[data-theme="light"] .broad__history-card-schedule .flex {
  margin-bottom: 0;
  font-weight: normal;
}

[data-theme="light"] .broad__history-card-schedule-data {
  font-weight: normal;
}