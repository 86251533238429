.btn {
  background: linear-gradient(90.3deg, #2196bb 0.16%, #0bdede 101.62%);
  font-size: 18px;
  font-weight: 500;
  line-height: 26.88px;
  padding: 12px 65px;
  border-radius: 15px;
  color: var(--color__white);
  display: block;
  width: fit-content;
  text-decoration: none;
}

.btn--full-width {
  width: 100%;
  text-align: center;
}

.btn-group {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 18px;
}

.login-btn {
  width: 58px;
  height: 44px;
  display: flex;
  align-items: center;
  border: 1px solid var(--color__white);
  justify-content: center;
  border-radius: 8px;
}

.dashboard__btn_white {
  background: var(--color__white);
  color: var(--color__black);
  font-size: 10px;
  font-weight: 700;
  line-height: 20px;
  text-align: center;
  padding-block: 10px;
  width: 100%;
  margin-top: 24px;
  border-radius: 28px;
}

.btn-continue,
.btn-back {
  color: var(--color__white);
  font-size: 30px;
  font-weight: 700;
  line-height: 20px;
  text-decoration: none;
  display: flex;
  align-content: center;
  justify-content: flex-end;
  gap: 6px;
}

.btn-continue {
  color: var(--color__secondary);
  margin-top: 50px;
}

.btn--started {
  border-radius: 44px;
  background: var(--color__secondary);
}

.btn--submit {
  padding-inline: 70px;
  border-radius: 42px;
  color: var(--color__black);
}

.btn--lunch,
.btn--schedule {
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 49px;
}

.btn--lunch {
  color: var(--color__broad-start-color);
  background: var(--color__broad-start-btn);
}

.btn--schedule {
  background: var(--color__broad-schedule-btn);
  color: #ffff;
}