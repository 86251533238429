/* Min-widths */
@custom-media --xxxs (min-width: 320px);
@custom-media --xxs (min-width: 445px);
@custom-media --xs (min-width: 620px);
@custom-media --s (min-width: 768px);
@custom-media --ms (min-width: 840px);
@custom-media --m (min-width: 1000px);
@custom-media --ml (min-width: 1024px);
@custom-media --l (min-width: 1200px);
@custom-media --xl (min-width: 1440px);
@custom-media --xlg (min-width: 1600px);
@custom-media --xxlg (min-width: 1660px);
@custom-media --xxl (min-width: 1800px);
@custom-media --xxxl (min-width: 1920px);
