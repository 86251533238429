.import-container {
  background-color: #12141d;
  padding: 20px;
  border-radius: 10px;
  max-width: 800px;
  margin: auto;
}

.button-group {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.nav-button {
  background-color: #1f2430;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
}

.nav-button.active {
  background-color: #00e0ff;
  color: black;
}

.import-box {
  background-color: #1f2430;
  padding: 20px;
  border-radius: 10px;
  color: white;
}

.import-title {
  color: #00e0ff;
  text-align: center;
  margin-bottom: 15px;
}

.import-row {
  display: flex;
  align-items: center;
  background-color: #000;
  border-radius: 8px;
  padding: 10px;
}

.link-label {
  background-color: #333;
  color: white;
  padding: 10px;
  border-radius: 5px;
  margin-right: 10px;
}

.link-input {
  flex-grow: 1;
  background: none;
  border: none;
  color: white;
  outline: none;
  font-size: 14px;
}

.import-button {
  background-color: #00e0ff;
  color: black;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
}

.select-container {
  display: flex;
  gap: 10px;
  margin-top: 20px;
}

.custom-select {
  background-color: #1f2430;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 5px;
  width: 100%;
  font-size: 16px;
}

.scraped-data p {
  color: white;
  margin-bottom: 20px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 20px;
}

.scraped-img {
  display: flex;
  flex-wrap: wrap; /* Allow images to wrap to the next line */
  /*justify-content: center;*/ /* Center the images horizontally */
  margin: 0;
}

.scraped-img img {
  flex: 1 1 calc(33.33% - 20px); /* Each image takes up 1/3 of the container width minus margin */
  max-width: 200px; /* Adjust the max-width as needed */
  margin: 10px;
  border: 1px solid #ccc;
  /*box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);*/ /* Add a shadow for card effect */
  border-radius: 8px; /* Rounded corners for card effect */
  background-color: #fff; /* White background for card effect */
  /*padding: 10px;*/ /* Padding inside the card */
}
