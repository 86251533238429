.flex { display: flex; }
.flex-wrap { flex-wrap: wrap; }
.flex-no-wrap { flex-wrap: nowrap; }

.align-start { align-items: flex-start; }
.align-end { align-items: flex-end }
.align-center { align-items: center; }

.justify-content-center { justify-content: center; }
.justify-content-between { justify-content: space-between; }
.justify-content-start { justify-content: flex-start; }

.flex-direction-column { flex-direction: column; }
.flex-direction-row-reverse { flex-direction: row-reverse; }

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-grow-1 {
  flex-grow: 1;
}

.gap-24 {
  gap: 24px;
}

.gap-11 {
  gap: 11px;
}

.gap-200 {
  gap: 200px;
}

.gap-5 {
  gap: 5px;
}