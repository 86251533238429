
.chat {
  padding-inline: 40px 56px;
  display: grid;
  grid-template-columns: 350px 1fr;
  gap: 5px;

  &__recent {
    padding-top: 50px;
    display: flex;
    flex-direction: column;
    gap: 32px;
    height: 80vh;
    overflow: auto;

    &::-webkit-scrollbar {
      width: 0;
    }

    &-chat {
      background-color: var(--color__dashboard-card);
      display: flex;
      align-content: center;
      color: var(--color__table);
      padding: 10px 20px;
      gap: 12px;
      border-radius: 10px;
      position: relative;

      &:not(:last-child)::after {
        content: "";
        display: block;
        width: 312px;
        height: 1px;
        background-color: var(--color__chat-border);
        margin-top: 10px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: -16px;
      }

      span:nth-child(1) {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 196px;
      }
    }
  }

  &__area {
    background-color: var(--color__dashboard-card);
    position: relative;
    height: 80vh;
  }

  &__header {
    padding: 20px 20px 20px 50px;
    background-color: var(--color__black);
    color: var(--color__white);

    @media (max-width: 1340px) {
      padding-left: 20px;
    }

    .chat-pic {
      margin-right: 12px;
    }

    &-action {
      display: flex;
      gap: 24px;
    }

    &-action button {
      width: 52px;
      height: 52px;
      border: 1px solid #949494;
      border-radius: 50%;
    }
  }

  &__footer {
    position: absolute;
    bottom: 15px;
    width: calc(100% - 20px);
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;

    input {
      background-color: var(--color__chat-input);
      padding: 10px 20px;
      border: none;
      outline: none;
      width: 100%;
      color: var(--color__table);
      font-size: 14px;
      font-weight: 500;
      line-height: 21px;
      letter-spacing: -0.02em;
    }

    button {
      padding: 10px;

      path {
        stroke: var(--color__chat-submit);
      }
    }
  }
}

.online {
  &::before {
    content: "";
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #25C78B;
    margin-right: 5px;
  }
}

.chat-pic {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.chat-name {
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: -0.02em;
}

.chat-message {
  font-size: 12px;
  font-weight: 400;
  line-height: 15.12px;
  letter-spacing: -0.01em;
  margin-top: 8px;
}

.unread {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: var(--color__unread);
}

.seen {
  display: inline-block;
  width: 18px;
  height: 18px;
  background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.5001 5.24988L12.4426 4.19238L7.68762 8.94738L8.74512 10.0049L13.5001 5.24988ZM16.6801 4.19238L8.74512 12.1274L5.61012 8.99988L4.55262 10.0574L8.74512 14.2499L17.7451 5.24988L16.6801 4.19238ZM0.307617 10.0574L4.50012 14.2499L5.55762 13.1924L1.37262 8.99988L0.307617 10.0574Z' fill='%2304A4F4'/%3E%3C/svg%3E%0A");
}