@import 'variables/breakpoints';

:root {
  /* Colors */
  --color__primary: #131313;
  --color__secondary: #0BDEDE;
  --color__black: #000;
  --color__white: #ffff;
  --color__table: #EEEEEE;
  --color__mode: #F4F4F4;
  --color__mode-inactive: #343434;
  --color__sidebar-bg: #131313;
  --color__input-bg: #333232;
  --color__dark-secondary: #2196BB;
  --color__status: #514E4E;
  --color__progress: #262626;
  --color__progress-bar: #0BDEDE;
  --color__checkbox: #C0C0C0;
  --color__dashboard-card: #131313;
  --color__broad: #222222;
  --color__broad-btn: #0BDEDE;
  --color__broad-start-btn: #0BDEDE;
  --color__broad-start-color: #020202;
  --color__broad-schedule-btn: #3C3B3B;
  --color__broad-history-active: #201F1F;
  --color__schedule-date: #75BDD4;
  --color__chat-border: #595959;
  --color__chat-input: #2E2C2C;
  --color__unread: #DB5962;
  --color__chat-submit: #0BDEDE;
  --color__calendar: rgba(19, 19, 19, 1);
  --color__calendar-text: #EEEEEE;
  --color__input: #353535;

  /* Fonts */
  --font__headings: "League Spartan", sans-serif;
  --font__body: "League Spartan", sans-serif;

  /* size */
  --width__sidebar: 278px;
}

@media (max-width: 1600px) {
  :root {
    --width__sidebar: 220px;
  }
}


[data-theme="light"] {
  --color__sidebar-bg: #ffff;
  --color__primary: #F0F0F0;
  --color__black: #fff;
  --color__white: #000;
  --color__table: #000000;
  --color__mode: #2195BA;
  /* --color__mode: #343434; */
  /* --color__mode-inactive: #EDEDED; */
  --color__dashboard-card: #FAFAFA;
  --color__progress: ##E3E3E3;

  --color__broad: #F0EEEE;
  --color__broad-btn: #2195BABD;
  --color__broad-start-btn: #2195BABD;
  --color__broad-start-color: white;
  --color__broad-schedule-btn: #3C3B3B;
  --color__broad-history-active: #EDEDED;
  --color__schedule-date: #07A8A8;

  --color__chat-border: #595959;
  --color__chat-input: #DDDDDD;
  --color__chat-submit: #828282;
  --color__calendar: #FAFAFA;
  --color__calendar-text: #000000;

   --color__input: #E3E3E3;
}
